<script lang="ts">

	interface Props {
		height?: string; // Default height
		width?: string; // Default width
		class?: string;
	}

	let { height = '48px', width = '48px', class: className = '' }: Props = $props();
	
</script>

<svg
	xmlns="http://www.w3.org/2000/svg"
	fill="none"
	viewBox="0 0 48 48"
	{height}
	{width}
	class={className}
>
	<path
		fill-rule="evenodd"
		clip-rule="evenodd"
		d="M11 0H44C46.2091 0 48 1.79086 48 4V37C48 39.2091 46.2091 41 44 41H11C8.79086 41 7 39.2091 7 37V4C7 1.79086 8.79086 0 11 0ZM28.222 29.312L36.304 20.88C38.0662 19.1191 38.5029 16.4278 37.388 14.2C36.5557 12.5354 34.9737 11.3725 33.1366 11.0749C31.2994 10.7773 29.4313 11.3813 28.116 12.698L27.5 13.31L26.886 12.694C25.5678 11.3819 23.7004 10.7815 21.8646 11.0797C20.0287 11.3778 18.4472 12.5382 17.612 14.2C16.4987 16.4203 16.9296 19.1038 18.682 20.864L26.78 29.312C26.9685 29.5082 27.2289 29.6191 27.501 29.6191C27.7731 29.6191 28.0335 29.5082 28.222 29.312Z"
		fill="currentColor"
	/>
	<path
		d="M39 44H5C4.44772 44 4 43.5523 4 43V9C4 7.89543 3.10457 7 2 7C0.89543 7 0 7.89543 0 9V44C0 46.2091 1.79086 48 4 48H39C40.1046 48 41 47.1046 41 46C41 44.8954 40.1046 44 39 44Z"
		fill="currentColor"
	/>
</svg>
