<script lang="ts">
	interface Props {
		height?: string; // Default height
		width?: string; // Default width
		class?: string;
	}

	let { height = '48px', width = '48px', class: className = '' }: Props = $props();
	
</script>

<svg
	xmlns="http://www.w3.org/2000/svg"
	fill="none"
	viewBox="0 0 48 48"
	{height}
	{width}
	class={className}
>
	<path
		d="M8 31.94L22.528 17.412C23.0795 16.8627 23.9713 16.8627 24.5228 17.412L39.0508 31.94"
		stroke="currentColor"
		stroke-width="3.5"
		stroke-linecap="round"
		stroke-linejoin="round"
	/>
</svg>
