<script lang="ts">
	interface Props {
		height?: string; // Default height
		width?: string; // Default width
		class?: string;
	}

	let { height = '48px', width = '48px', class: className = '' }: Props = $props();
	
</script>

<svg
	xmlns="http://www.w3.org/2000/svg"
	fill="none"
	viewBox="0 0 48 48"
	{height}
	{width}
	class={className}
>
	<path
		fill-rule="evenodd"
		clip-rule="evenodd"
		d="M4 2H44C46.2091 2 48 3.79086 48 6V33C48 35.2091 46.2091 37 44 37H24.624L12.138 45.646C11.5262 46.0694 10.7299 46.1181 10.071 45.7725C9.41208 45.4268 8.99951 44.744 9 44V37H4C2.94961 37.0253 1.93332 36.6263 1.18065 35.8932C0.42797 35.1601 0.00238559 34.1547 0 33.104V6C0 3.79086 1.79086 2 4 2ZM43.5 33C43.7761 33 44 32.7761 44 32.5V6.5C44 6.22386 43.7761 6 43.5 6H4.5C4.22386 6 4 6.22386 4 6.5V32.5C4 32.7761 4.22386 33 4.5 33H11C12.1046 33 13 33.8954 13 35V40.182L22.862 33.356C23.1963 33.1244 23.5933 33.0002 24 33H43.5Z"
		fill="currentColor"
	/>
	<path
		d="M12 17H36C37.1046 17 38 16.1046 38 15C38 13.8954 37.1046 13 36 13H12C10.8954 13 10 13.8954 10 15C10 16.1046 10.8954 17 12 17Z"
		fill="currentColor"
	/>
	<path
		d="M12 26H36C37.1046 26 38 25.1046 38 24C38 22.8954 37.1046 22 36 22H12C10.8954 22 10 22.8954 10 24C10 25.1046 10.8954 26 12 26Z"
		fill="currentColor"
	/>
</svg>
