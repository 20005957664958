import type { BlockPosition, DraggedItem, DropTarget } from '$lib/context/dnd-context'

// Auto-scroll configuration
const SCROLL_SPEED = 15
const SCROLL_THRESHOLD = 50
const WINDOW_SCROLL_THRESHOLD = 80
const WINDOW_SCROLL_SPEED = 10

// Track if auto-scroll is active
let autoScrollInterval: number | null = null

// Function to clear auto-scroll interval
export function clearAutoScroll() {
	if (autoScrollInterval) {
		clearInterval(autoScrollInterval)
		autoScrollInterval = null
	}
}

export function handleAutoScroll(e: MouseEvent | TouchEvent) {
	const x = e instanceof MouseEvent ? e.clientX : e.touches[0].clientX
	const y = e instanceof MouseEvent ? e.clientY : e.touches[0].clientY

	// Clear any existing auto-scroll interval
	if (autoScrollInterval) {
		clearInterval(autoScrollInterval)
		autoScrollInterval = null
	}

	// Get viewport dimensions
	const viewportHeight = window.innerHeight
	const viewportWidth = window.innerWidth

	// Check if we need to scroll the window
	let windowScrollX = 0
	let windowScrollY = 0

	// Vertical window scrolling
	if (y < WINDOW_SCROLL_THRESHOLD) {
		windowScrollY = -WINDOW_SCROLL_SPEED
	} else if (y > viewportHeight - WINDOW_SCROLL_THRESHOLD) {
		windowScrollY = WINDOW_SCROLL_SPEED
	}

	// Horizontal window scrolling
	if (x < WINDOW_SCROLL_THRESHOLD) {
		windowScrollX = -WINDOW_SCROLL_SPEED
	} else if (x > viewportWidth - WINDOW_SCROLL_THRESHOLD) {
		windowScrollX = WINDOW_SCROLL_SPEED
	}

	// Check for scrollable containers
	const containers = document.querySelectorAll('.dnd-segment')
	let containerScrolling = false

	containers.forEach((container) => {
		const rect = container.getBoundingClientRect()
		const isInHorizontalBounds = x >= rect.left && x <= rect.right
		const isInVerticalBounds = y >= rect.top && y <= rect.bottom

		// Only scroll container if mouse is within or very close to its bounds
		if (isInHorizontalBounds && isInVerticalBounds) {
			// Vertical container scrolling
			if (y < rect.top + SCROLL_THRESHOLD) {
				container.scrollTop -= SCROLL_SPEED
				containerScrolling = true
			} else if (y > rect.bottom - SCROLL_THRESHOLD) {
				container.scrollTop += SCROLL_SPEED
				containerScrolling = true
			}

			// Horizontal container scrolling (if needed)
			if (x < rect.left + SCROLL_THRESHOLD) {
				container.scrollLeft -= SCROLL_SPEED
				containerScrolling = true
			} else if (x > rect.right - SCROLL_THRESHOLD) {
				container.scrollLeft += SCROLL_SPEED
				containerScrolling = true
			}
		}
	})

	// If we need to scroll the window and no container is being scrolled
	if ((windowScrollX !== 0 || windowScrollY !== 0) && !containerScrolling) {
		// Set up continuous smooth scrolling
		autoScrollInterval = window.setInterval(() => {
			window.scrollBy({
				top: windowScrollY,
				left: windowScrollX,
				behavior: 'auto', // Use 'auto' for more responsive scrolling during drag
			})
		}, 16) // ~60fps for smooth scrolling
	}
}

// Accessibility helpers
export function announceToScreenReader(message: string) {
	const announcement = document.createElement('div')
	announcement.setAttribute('role', 'status')
	announcement.setAttribute('aria-live', 'polite')
	announcement.className = 'sr-only'
	announcement.textContent = message
	document.body.appendChild(announcement)

	// Remove after announcement
	setTimeout(() => {
		document.body.removeChild(announcement)
	}, 1000)
}

// Enhanced position calculation
export function calculateEnhancedPositionUpdates(
	draggedItem: DraggedItem,
	dropTarget: DropTarget,
	allItems: Map<string, DraggedItem>,
): BlockPosition[] {
	const updates: BlockPosition[] = []
	const sourceItems = Array.from(allItems.values())
		.filter((item) => item.parentId === draggedItem.parentId)
		.sort((a, b) => a.position - b.position)

	const targetItems = Array.from(allItems.values())
		.filter((item) => item.parentId === dropTarget.parentId)
		.sort((a, b) => a.position - b.position)

	if (draggedItem.parentId === dropTarget.parentId) {
		// Same container reordering
		return handleSameContainerReorder(draggedItem, dropTarget, sourceItems)
	} else if (draggedItem.type === dropTarget.type) {
		// Moving between containers of same type
		return handleCrossContainerMove(draggedItem, dropTarget, sourceItems, targetItems)
	} else {
		// Moving between different types (extra <-> day)
		return handleCrossTypeMove(draggedItem, dropTarget, sourceItems, targetItems)
	}
}

function handleSameContainerReorder(
	draggedItem: DraggedItem,
	dropTarget: DropTarget,
	items: DraggedItem[],
): BlockPosition[] {
	const updates: BlockPosition[] = []
	const newPosition = dropTarget.position
	const oldPosition = draggedItem.position

	items.forEach((item) => {
		if (item.id === draggedItem.id) {
			updates.push({
				id: item.id,
				position: newPosition,
				parentId: draggedItem.parentId,
			})
		} else if (
			oldPosition < newPosition &&
			item.position > oldPosition &&
			item.position <= newPosition
		) {
			updates.push({
				id: item.id,
				position: item.position - 1,
				parentId: draggedItem.parentId,
			})
		} else if (
			oldPosition > newPosition &&
			item.position >= newPosition &&
			item.position < oldPosition
		) {
			updates.push({
				id: item.id,
				position: item.position + 1,
				parentId: draggedItem.parentId,
			})
		}
	})

	return updates
}

function handleCrossContainerMove(
	draggedItem: DraggedItem,
	dropTarget: DropTarget,
	sourceItems: DraggedItem[],
	targetItems: DraggedItem[],
): BlockPosition[] {
	const updates: BlockPosition[] = []

	// Move dragged item
	updates.push({
		id: draggedItem.id,
		position: dropTarget.position,
		parentId: dropTarget.parentId,
	})

	// Update source container
	sourceItems
		.filter((item) => item.id !== draggedItem.id)
		.forEach((item, index) => {
			updates.push({
				id: item.id,
				position: index,
				parentId: draggedItem.parentId,
			})
		})

	// Update target container
	targetItems
		.filter((item) => item.position >= dropTarget.position)
		.forEach((item) => {
			updates.push({
				id: item.id,
				position: item.position + 1,
				parentId: dropTarget.parentId,
			})
		})

	return updates
}

function handleCrossTypeMove(
	draggedItem: DraggedItem,
	dropTarget: DropTarget,
	sourceItems: DraggedItem[],
	targetItems: DraggedItem[],
): BlockPosition[] {
	const updates = handleCrossContainerMove(draggedItem, dropTarget, sourceItems, targetItems)

	// Additional processing for type-specific requirements could be added here

	return updates
}
