<script lang="ts">
	interface Props {
		height?: string; // Default height
		width?: string; // Default width
		class?: string;
	}

	let { height = '48px', width = '48px', class: className = '' }: Props = $props();
	
</script>

<svg
	xmlns="http://www.w3.org/2000/svg"
	fill="none"
	viewBox="0 0 48 48"
	{height}
	{width}
	class={className}
>
	<path
		d="M19.138 33H5C4.44772 33 4 32.5523 4 32V15C4 14.4477 4.44772 14 5 14H32C32.5523 14 33 14.4477 33 15V19.14C34.327 18.956 35.673 18.956 37 19.14V6C37 4.89543 36.1046 4 35 4H30C29.7239 4 29.5 3.77614 29.5 3.5V1.5C29.5 0.671573 28.8284 0 28 0C27.1716 0 26.5 0.671573 26.5 1.5V9C26.5 9.82843 25.8284 10.5 25 10.5C24.1716 10.5 23.5 9.82843 23.5 9V5C23.5 4.44772 23.0523 4 22.5 4H14C13.7239 4 13.5 3.77614 13.5 3.5V1.5C13.5 0.671573 12.8284 0 12 0C11.1716 0 10.5 0.671573 10.5 1.5V9C10.5 9.82843 9.82843 10.5 9 10.5C8.17157 10.5 7.5 9.82843 7.5 9V5C7.5 4.44772 7.05228 4 6.5 4H2C0.89543 4 0 4.89543 0 6V33C0 35.2091 1.79086 37 4 37H19.138C18.954 35.673 18.954 34.327 19.138 33V33Z"
		fill="currentColor"
	/>
	<path
		fill-rule="evenodd"
		clip-rule="evenodd"
		d="M22 35C22 27.8203 27.8203 22 35 22C42.176 22.0088 47.9912 27.824 48 35C48 42.1797 42.1797 48 35 48C27.8203 48 22 42.1797 22 35ZM35 41.5C35.8284 41.5 36.5 40.8284 36.5 40V37C36.5 36.7239 36.7239 36.5 37 36.5H40C40.8284 36.5 41.5 35.8284 41.5 35C41.5 34.1716 40.8284 33.5 40 33.5H37C36.7239 33.5 36.5 33.2761 36.5 33V30C36.5 29.1716 35.8284 28.5 35 28.5C34.1716 28.5 33.5 29.1716 33.5 30V33C33.5 33.2761 33.2761 33.5 33 33.5H30C29.1716 33.5 28.5 34.1716 28.5 35C28.5 35.8284 29.1716 36.5 30 36.5H33C33.2761 36.5 33.5 36.7239 33.5 37V40C33.5 40.8284 34.1716 41.5 35 41.5Z"
		fill="currentColor"
	/>
</svg>
