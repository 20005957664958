<script lang="ts">
	import {
		setDndContext,
		type DraggedItem,
		type DragState,
		type DropTarget,
	} from '$lib/context/dnd-context'
	import type { BlockPositionUpdateInput } from '$lib/graphql/types'
	import { handleAutoScroll, clearAutoScroll } from '$lib/utils/dnd-helpers'
	import { onDestroy, type Snippet } from 'svelte'

	interface Props {
		destinationId: string
		onPositionsUpdate: (
			updates: BlockPositionUpdateInput[],
			movedBlockId: string | null,
		) => Promise<void>
		disabled?: boolean
		children: Snippet
	}

	let { destinationId, onPositionsUpdate, disabled = false, children }: Props = $props()

	// State using Runes
	let draggableItems = $state(new Map<string, DraggedItem>())
	let dropTargets = $state(new Map<string, DropTarget>())

	let dragState = $state<DragState>({
		isDragging: false,
		draggedItem: null,
		currentTarget: null,
		ghostElement: null,
		gapElement: null,
		initialMousePosition: { x: 0, y: 0 },
		currentMousePosition: { x: 0, y: 0 },
		offset: { x: 0, y: 0 },
		originalDisplay: null,
	})

	// Add click detection state
	let dragStartTime = $state(0)
	let dragStartPos = $state({ x: 0, y: 0 })
	const DRAG_THRESHOLD = 5 // pixels
	const CLICK_THRESHOLD = 200 // milliseconds

	// Registration functions
	function registerDraggable(
		element: HTMLElement,
		options: {
			id: string
			parentId: string
			position: number
			type: 'extra' | 'day' | 'highlight' | 'list' | string
			dayIndex?: number
		},
	) {
		draggableItems.delete(options.id)
		draggableItems.set(options.id, {
			...options,
			element,
		})
	}

	function unregisterDraggable(id: string) {
		draggableItems.delete(id)
	}

	function registerDropTarget(
		element: HTMLElement,
		options: {
			id: string
			parentId: string
			position: number
			type: 'extra' | 'day' | 'highlight' | 'list' | string
			dayIndex?: number
		},
	) {
		dropTargets.set(options.id, {
			...options,
			element,
		})
	}

	function unregisterDropTarget(id: string) {
		dropTargets.delete(id)
	}

	function handleDragStart(e: MouseEvent | TouchEvent, itemId: string) {
		if (disabled || !draggableItems.has(itemId)) return

		const item = draggableItems.get(itemId)!
		const initialX = e instanceof MouseEvent ? e.clientX : e.touches[0].clientX
		const initialY = e instanceof MouseEvent ? e.clientY : e.touches[0].clientY

		// Store start time and position for click detection
		dragStartTime = Date.now()
		dragStartPos = { x: initialX, y: initialY }

		// Don't create ghost or start drag immediately - wait for movement
		const handleInitialMove = (moveEvent: MouseEvent | TouchEvent) => {
			const currentX =
				moveEvent instanceof MouseEvent ? moveEvent.clientX : moveEvent.touches[0].clientX
			const currentY =
				moveEvent instanceof MouseEvent ? moveEvent.clientY : moveEvent.touches[0].clientY

			const deltaX = Math.abs(currentX - dragStartPos.x)
			const deltaY = Math.abs(currentY - dragStartPos.y)

			// If moved beyond threshold, initiate drag
			if (deltaX > DRAG_THRESHOLD || deltaY > DRAG_THRESHOLD) {
				// Remove ALL initial listeners
				document.removeEventListener('mousemove', handleInitialMove)
				document.removeEventListener('touchmove', handleInitialMove)
				document.removeEventListener('mouseup', handleInitialEnd)
				document.removeEventListener('touchend', handleInitialEnd)
				document.removeEventListener('touchcancel', handleInitialEnd)

				// Add visual feedback to original element
				item.element.classList.add('dragging')
				item.element.style.opacity = '0.4'

				// Create ghost element
				const ghost = createGhostElement(item.element)
				const ghostGap = createGhostGapElement(item.element)
				const rect = ghost.getBoundingClientRect()

				// Store original display value and hide the element
				const originalDisplay = item.element.style.display
				item.element.style.display = 'none'

				// Calculate offset from the ghost element's position
				const offsetX = currentX - rect.left
				const offsetY = currentY - rect.top

				// Update the drag state
				dragState = {
					isDragging: true,
					draggedItem: item,
					currentTarget: null,
					ghostElement: ghost,
					gapElement: ghostGap,
					initialMousePosition: { x: currentX, y: currentY },
					currentMousePosition: { x: currentX, y: currentY },
					offset: { x: offsetX, y: offsetY },
					originalDisplay,
				}

				// Add regular drag move/end handlers
				if (moveEvent instanceof MouseEvent) {
					document.addEventListener('mousemove', handleDragMove)
					document.addEventListener('mouseup', handleDragEnd)
				} else {
					document.addEventListener('touchmove', handleDragMove, { passive: false })
					document.addEventListener('touchend', handleDragEnd)
					document.addEventListener('touchcancel', handleDragEnd)
				}
			}
		}

		const handleInitialEnd = (endEvent: MouseEvent | TouchEvent) => {
			document.removeEventListener('mousemove', handleInitialMove)
			document.removeEventListener('touchmove', handleInitialMove)
			document.removeEventListener('mouseup', handleInitialEnd)
			document.removeEventListener('touchend', handleInitialEnd)
			document.removeEventListener('touchcancel', handleInitialEnd)

			// If drag hasn't started and it's within click threshold, it's a click
			if (!dragState.isDragging && Date.now() - dragStartTime < CLICK_THRESHOLD) {
				// Find click handler in dataset or props
				const clickHandler = item.element.dataset.onclick || item.element.onclick
				if (clickHandler && typeof clickHandler === 'function') {
					clickHandler.call(item.element, itemId)
				}
			}
		}

		// Add initial move/end listeners
		if (e instanceof MouseEvent) {
			document.addEventListener('mousemove', handleInitialMove)
			document.addEventListener('mouseup', handleInitialEnd)
		} else {
			document.addEventListener('touchmove', handleInitialMove, { passive: false })
			document.addEventListener('touchend', handleInitialEnd)
			document.addEventListener('touchcancel', handleInitialEnd)
		}

		e.preventDefault()
	}

	// Function to calculate drop position based on indices
	function calculateDropPosition(
		containerElement: HTMLElement,
		mouseY: number,
		blocks: any[],
		draggedItemId: string,
	): number {
		const containerRect = containerElement.getBoundingClientRect()
		const relativeMouseY = mouseY - containerRect.top

		// Get all visible block elements (excluding the dragged item)
		const blockElements = Array.from(
			containerElement.querySelectorAll<HTMLElement>('[data-block-item]'),
		).filter((el) => el.getAttribute('data-block-id') !== draggedItemId)

		if (blockElements.length === 0) {
			return 0
		}

		// Find the index based on relative position
		for (let i = 0; i < blockElements.length; i++) {
			const blockRect = blockElements[i].getBoundingClientRect()
			const blockMiddle = blockRect.top + blockRect.height / 2 - containerRect.top

			if (relativeMouseY < blockMiddle) {
				return i
			}
		}

		return blockElements.length
	}

	function handleDragMove(e: MouseEvent | TouchEvent) {
		if (!dragState.isDragging || !dragState.ghostElement || !dragState.draggedItem) return

		const x = e instanceof MouseEvent ? e.clientX : e.touches[0].clientX
		const y = e instanceof MouseEvent ? e.clientY : e.touches[0].clientY

		// Handle auto-scroll
		handleAutoScroll(e)

		// Position ghost element
		dragState.ghostElement.style.left = `${x - dragState.offset.x}px`
		dragState.ghostElement.style.top = `${y - dragState.offset.y}px`

		// Update current mouse position
		dragState.currentMousePosition = { x, y }

		const target = getDropTargetAt(x, y)
		if (target && isValidDropTarget(target.id)) {
			dragState.currentTarget = target
			// target.element.classList.add('valid-drop-target')

			// Get the blocks for the current target
			const targetBlocks = Array.from(draggableItems.values())
				.filter((item) => item.parentId === target.parentId)
				.filter((item) => item.id !== dragState.draggedItem!.id) // Exclude dragged item
				.sort((a, b) => a.position - b.position)

			// Calculate new position
			const newPosition = calculateDropPosition(
				target.element,
				y,
				targetBlocks,
				dragState.draggedItem.id,
			)

			// Update drop target position
			updateDropTargetPosition(target.id, newPosition)

			// Handle gap visualization
			if (dragState.gapElement instanceof HTMLElement) {
				handleGapPlacement(
					dragState.gapElement,
					target.element,
					dragState.draggedItem.id,
					newPosition,
				)
			}
		} else {
			dragState.currentTarget = null
		}

		e.preventDefault()
	}

	function handleGapPlacement(
		gapElement: HTMLElement,
		targetContainer: HTMLElement,
		draggedItemId: string,
		newPosition: number,
	) {
		// Get all block elements, excluding the dragged item
		const blockElements = Array.from(
			targetContainer.querySelectorAll<HTMLElement>('[data-block-item]'),
		).filter((el) => el.getAttribute('data-block-id') !== draggedItemId)

		// console.log('blockElements', blockElements)
		// console.log('newPosition', newPosition)
		// console.log('targetContainer', targetContainer)
		// console.log('gapElement', gapElement)

		// If container is empty or all items filtered out, append to container
		if (blockElements.length === 0) {
			targetContainer.appendChild(gapElement)
			return
		}

		// If position is at the start
		if (newPosition === 0) {
			if (blockElements.length > 0) {
				blockElements[0].insertAdjacentElement('beforebegin', gapElement)
			} else {
				targetContainer.appendChild(gapElement)
			}
			return
		}

		// If position is at the end
		if (newPosition >= blockElements.length) {
			if (blockElements.length > 0) {
				blockElements[blockElements.length - 1].insertAdjacentElement('afterend', gapElement)
			} else {
				targetContainer.appendChild(gapElement)
			}
			return
		}

		// Position between elements
		blockElements[newPosition].insertAdjacentElement('beforebegin', gapElement)
	}

	function cleanupGap() {
		if (dragState.gapElement) {
			dragState.gapElement.remove()
			dragState.gapElement = null
		}
	}

	function createGhostGapElement(element: HTMLElement): HTMLElement {
		const ghost = element.cloneNode(true) as HTMLElement

		return ghost
	}

	function createGhostElement(element: HTMLElement): HTMLElement {
		const ghost = element.cloneNode(true) as HTMLElement
		const rect = element.getBoundingClientRect()

		Object.assign(ghost.style, {
			position: 'fixed',
			top: `${rect.top}px`,
			left: `${rect.left}px`,
			width: `${element.offsetWidth}px`,
			height: `${element.offsetHeight}px`,
			margin: '0',
			padding: '0',
			opacity: '0.8',
			zIndex: '1000',
			pointerEvents: 'none',
			boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
			cursor: 'grabbing',
		})

		ghost.classList.add('ghost-element')
		document.body.appendChild(ghost)
		return ghost
	}

	async function handleDragEnd(e: MouseEvent | TouchEvent) {
		if (!dragState.isDragging || !dragState.draggedItem) return

		// Remove event listeners
		document.removeEventListener('mousemove', handleDragMove)
		document.removeEventListener('mouseup', handleDragEnd)
		document.removeEventListener('touchmove', handleDragMove)
		document.removeEventListener('touchend', handleDragEnd)
		document.removeEventListener('touchcancel', handleDragEnd)

		// Clear any auto-scrolling
		clearAutoScroll()

		const { draggedItem, currentTarget } = dragState

		// Clean up ghost element
		if (dragState.ghostElement) {
			dragState.ghostElement.style.transition = 'opacity 0.2s ease-out'
			dragState.ghostElement.style.opacity = '0'
			setTimeout(() => {
				dragState.ghostElement?.remove()
			}, 200)
		}

		// Reset original element styles
		draggedItem.element.classList.remove('dragging')
		draggedItem.element.style.opacity = ''

		// Remove drop target highlighting
		if (currentTarget) {
			currentTarget.element.classList.remove('valid-drop-target')
		}

		// Handle the drop if we have a valid target
		if (currentTarget && isValidDropTarget(currentTarget.id)) {
			try {
				const { updates, movedBlockId } = calculatePositionUpdates(
					draggedItem,
					currentTarget,
					draggableItems,
				)
				if (updates.length > 0) {
					await onPositionsUpdate(updates, movedBlockId)
				}
			} catch (error) {
				console.error('Error updating block positions:', error)
				// Here you could add error handling UI feedback
			}
		}

		cleanupGap()

		// Restore the original element's display
		draggedItem.element.style.display = dragState.originalDisplay ?? ''

		// Reset drag state
		dragState = {
			isDragging: false,
			draggedItem: null,
			currentTarget: null,
			ghostElement: null,
			gapElement: null,
			initialMousePosition: { x: 0, y: 0 },
			currentMousePosition: { x: 0, y: 0 },
			offset: { x: 0, y: 0 },
			originalDisplay: null,
		}

		e.preventDefault()
	}

	function getDropTargetAt(x: number, y: number): DropTarget | null {
		for (const target of dropTargets.values()) {
			const rect = target.element.getBoundingClientRect()
			if (x >= rect.left && x <= rect.right && y >= rect.top && y <= rect.bottom) {
				return target
			}
		}
		return null
	}

	function updateDropTargetPosition(targetId: string, newPosition: number) {
		if (!dropTargets.has(targetId)) return
		// console.log('updateDropTargetPosition', targetId, newPosition)

		const target = dropTargets.get(targetId)!
		dropTargets.set(targetId, {
			...target,
			position: newPosition,
		})
	}

	function isValidDropTarget(targetId: string): boolean {
		if (!dragState.draggedItem || !dropTargets.has(targetId)) return false
		const target = dropTargets.get(targetId)!

		// Prevent dropping onto itself
		if (target.id === dragState.draggedItem.id) return false

		// Always allow drops between items of the same type (highlights, extra, day)
		if (target.type === dragState.draggedItem.type) return true

		// For blocks, allow specific cross-type drops
		if (target.type === 'extra' || target.type === 'day') {
			// Allow drops from extra to day
			if (dragState.draggedItem.type === 'extra' && target.type === 'day') return true

			// Allow drops from day to extra
			if (dragState.draggedItem.type === 'day' && target.type === 'extra') return true
		}

		return false
	}

	function calculatePositionUpdates(
		draggedItem: DraggedItem,
		dropTarget: DropTarget,
		draggableItems: Map<string, DraggedItem>,
	): { updates: BlockPositionUpdateInput[]; movedBlockId: string | null } {
		const updates: BlockPositionUpdateInput[] = []
		const draggedItemParent = draggedItem.parentId
		const dropTargetParent = dropTarget.parentId

		// Get all items in source and target containers, properly sorted
		const sourceItems = [...draggableItems.values()]
			.filter((item) => item.parentId === draggedItemParent)
			.sort((a, b) => a.position - b.position)

		const targetItems = [...draggableItems.values()]
			.filter((item) => item.parentId === dropTargetParent)
			.sort((a, b) => a.position - b.position)

		if (draggedItemParent === dropTargetParent) {
			// Same container reordering
			const oldIndex = sourceItems.findIndex((item) => item.id === draggedItem.id)
			const newIndex = Math.min(dropTarget.position, sourceItems.length - 1)

			// If position hasn't changed, return empty updates
			if (oldIndex === newIndex) {
				return { updates: [], movedBlockId: draggedItem?.id }
			}

			// Remove item from old position
			const updatedItems = [...sourceItems]
			updatedItems.splice(oldIndex, 1)
			// Insert at new position
			updatedItems.splice(newIndex, 0, draggedItem)

			// Generate updates only for items whose position changed
			updatedItems.forEach((item, index) => {
				const originalItem = draggableItems.get(item.id)
				if (
					originalItem &&
					(originalItem.position !== index || originalItem.parentId !== draggedItemParent)
				) {
					updates.push({
						id: item.id,
						position: index,
						parentId: draggedItemParent,
					})
				}
			})
		} else {
			// Moving between different containers

			// 1. Update source container items
			const updatedSourceItems = sourceItems.filter((item) => item.id !== draggedItem.id)
			updatedSourceItems.forEach((item, index) => {
				const originalItem = draggableItems.get(item.id)
				if (
					originalItem &&
					(originalItem.position !== index || originalItem.parentId !== draggedItemParent)
				) {
					updates.push({
						id: item.id,
						position: index,
						parentId: draggedItemParent,
					})
				}
			})

			// 2. Update target container items
			const updatedTargetItems = [...targetItems]
			updatedTargetItems.splice(dropTarget.position, 0, {
				...draggedItem,
				parentId: dropTargetParent,
			})

			updatedTargetItems.forEach((item, index) => {
				const originalItem = draggableItems.get(item.id)
				if (
					originalItem &&
					(originalItem.position !== index || originalItem.parentId !== dropTargetParent)
				) {
					updates.push({
						id: item.id,
						position: index,
						parentId: dropTargetParent,
					})
				}
			})
		}

		return { updates, movedBlockId: draggedItem?.id }
	}

	// Cleanup
	onDestroy(() => {
		draggableItems.clear()
		dropTargets.clear()
		if (dragState.ghostElement) {
			dragState.ghostElement.remove()
		}
		clearAutoScroll()
	})

	// Create derived state for dragState store
	let dragStateReadable = $derived(dragState)

	// Set up context
	setDndContext({
		registerDraggable,
		unregisterDraggable,
		registerDropTarget,
		unregisterDropTarget,
		dragState: {
			subscribe: (fn) => {
				fn(dragStateReadable)
				return () => {}
			},
		},
		handleDragStart,
		handleDragMove,
		handleDragEnd,
		isDragging: () => dragState.isDragging,
		isValidDropTarget,
		getDropTargetAt,
		calculatePositionUpdates,
		updatePositions: onPositionsUpdate,
		updateDropTargetPosition,
	})
</script>

<div class="dnd-segment" data-destination-id={destinationId}>
	{@render children?.()}
</div>
