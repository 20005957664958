<script lang="ts">
	import { BlockType } from '$lib/graphql/enums'
	import { getCustomColorBgClassName } from '$lib/utils/custom-colors'
	import { getCustomIconSvgUrl } from '$lib/utils/custom-icons'
	import type { BlockPositionUpdateInput } from '$lib/graphql/types'
	import type { BlockFieldsFragment } from '$lib/queries/fragments/generated/BlockFields'
	import type { PublicBlockFieldsFragment } from '$lib/queries/fragments/generated/PublicBlockFields'
	import BlockTemplate from './BlockTemplate.svelte'
	import ListSection from './ListSection.svelte'
	import { getDndContext } from '$lib/context/dnd-context'

	interface Props {
		destinationBlockId: string
		extraBlockId: string
		orderedExtraBlocks?: (BlockFieldsFragment | PublicBlockFieldsFragment)[]
		descedantBlocks?: (BlockFieldsFragment | PublicBlockFieldsFragment)[]
		isPublic?: boolean
		isImmutable?: boolean
		isLoading?: boolean
		isMobileScreen?: boolean
		previewBlocks?: string[]
		expandedBlockId?: string | null
		isMoving?: boolean
		blocksUpdating?: BlockPositionUpdateInput[]
		isDndModal?: boolean
		onexpand?: (blockId: string | null) => void
		onclick?: (blockId: string) => void
		onsetMoving?: () => void
	}

	let {
		destinationBlockId,
		extraBlockId,
		orderedExtraBlocks = [],
		descedantBlocks = [],
		isPublic = false,
		isImmutable = false,
		isLoading = false,
		isMobileScreen = false,
		previewBlocks = [],
		expandedBlockId = null,
		isMoving = false,
		blocksUpdating = [],
		isDndModal = false,
		onexpand,
		onclick,
		onsetMoving,
	}: Props = $props()

	let extraBlocksElement: HTMLElement | null = $state(null)

	// Get DnD context
	const dndContext = getDndContext()

	$effect(() => {
		if (extraBlocksElement && !isImmutable && !isPublic && !isMobileScreen) {
			// Register container as drop target with initial position
			dndContext.registerDropTarget(extraBlocksElement, {
				id: `${extraBlockId}-container`,
				parentId: extraBlockId,
				position: orderedExtraBlocks.length,
				type: 'extra',
			})

			// Cleanup
			return () => {
				dndContext.unregisterDropTarget(`${extraBlockId}-container`)
			}
		}
	})

	// Register draggable blocks
	function registerBlockAction(
		element: HTMLElement,
		{ block, index }: { block: BlockFieldsFragment | PublicBlockFieldsFragment; index: number },
	) {
		if (!isImmutable && !isPublic && !isMobileScreen) {
			// Add data attribute for position calculation
			element.setAttribute('data-block-item', '')
			element.setAttribute('data-position', index.toString())
			element.setAttribute('data-block-id', block.id)

			dndContext.registerDraggable(element, {
				id: block.id,
				parentId: extraBlockId,
				position: index,
				type: 'extra',
			})
		}

		return {
			update({ block: newBlock, index: newIndex }) {
				if (!isImmutable && !isPublic && !isMobileScreen) {
					element.setAttribute('data-position', newIndex.toString())
					dndContext.registerDraggable(element, {
						id: newBlock.id,
						parentId: extraBlockId,
						position: newIndex,
						type: 'extra',
					})
				}
			},
			destroy() {
				if (!isImmutable && !isPublic && !isMobileScreen) {
					dndContext.unregisterDraggable(block.id)
				}
			},
		}
	}

	// Handle touch events
	function handleTouchStart(e: MouseEvent | TouchEvent, blockId: string) {
		if (!isImmutable && !isPublic && !isMobileScreen) {
			onsetMoving?.()
			dndContext.handleDragStart(e, blockId)
		}
	}
</script>

<div
	bind:this={extraBlocksElement}
	id={`${destinationBlockId}-extra-blocks`}
	data-area="extra"
	role="list"
	class="py-[1px]"
>
	{#each orderedExtraBlocks as block, index (block?.id)}
		{@const color = getCustomColorBgClassName(block?.color, block?.blockType)}
		{@const icon = getCustomIconSvgUrl(block?.icon, block?.blockType)}
		{@const isBlockPositionUpdating = blocksUpdating.some((update) => update.id === block?.id)}

		<!-- svelte-ignore a11y_no_static_element_interactions -->
		<!-- ontouchstart={(e) => handleTouchStart(e, block.id)} -->
		<div
			use:registerBlockAction={{ block, index }}
			onmousedown={(e) => handleTouchStart(e, block.id)}
			ontouchstart={(e) => handleTouchStart(e, block.id)}
			data-block-item
			data-position={index}
		>
			{#if block.blockType === BlockType.List}
				{@const childBlocks = descedantBlocks
					.filter((b) => b.parentId === block?.id)
					?.sort((a, b) => a?.position - b?.position)}
				<ListSection
					{isBlockPositionUpdating}
					{previewBlocks}
					{onclick}
					listBlock={block}
					numInList={block?.numInList}
					{childBlocks}
					iconUrl={icon}
					bgColor={color}
					hasActions={!isImmutable && !isPublic}
					{isImmutable}
					{isPublic}
					{isLoading}
					{isMoving}
					{expandedBlockId}
					{isDndModal}
					{onexpand}
				/>
			{:else}
				<BlockTemplate
					{isBlockPositionUpdating}
					{previewBlocks}
					title={block?.title}
					description={block?.description}
					blockId={block?.id}
					{isPublic}
					{isLoading}
					{isMoving}
					{expandedBlockId}
					{isImmutable}
					{isDndModal}
					iconUrl={icon}
					bgColor={color}
					hasActions={!isImmutable && !isPublic}
					{onclick}
					{onexpand}
				/>
			{/if}
		</div>
	{/each}
</div>
