import type { BlockPositionUpdateInput } from '$lib/graphql/types'
import { getContext, setContext } from 'svelte'
import type { Readable } from 'svelte/store'

export interface BlockPosition {
	id: string
	position: number
	parentId: string
}

export interface DraggedItem {
	id: string
	parentId: string
	position: number
	element: HTMLElement
	type: 'extra' | 'day' | 'highlight' | string
	dayIndex?: number
}

export interface DropTarget {
	id: string
	parentId: string
	position: number
	element: HTMLElement
	type: 'extra' | 'day' | 'highlight' | string
	dayIndex?: number
}

export interface DragState {
	isDragging: boolean
	draggedItem: DraggedItem | null
	currentTarget: DropTarget | null
	ghostElement: HTMLElement | null
	gapElement: HTMLElement | null
	initialMousePosition: { x: number; y: number }
	currentMousePosition: { x: number; y: number }
	offset: { x: number; y: number }
	originalDisplay: string | null
}

export interface DropTargetMetadata {
	id: string
	parentId: string
	position: number
	type: 'extra' | 'day' | 'highlight' | string
	dayIndex?: number
}

export interface DragItemMetadata {
	id: string
	parentId: string
	position: number
	type: 'extra' | 'day'
	dayIndex?: number
}

export interface DragPositionConfig {
	threshold?: number // Minimum distance to trigger position change
	scrollOffset?: number // Account for container scrolling
	deadzone?: number // Minimum distance from edges
}

const DND_CONTEXT_KEY = Symbol('dnd-segment')

export interface DndSegmentContextValue {
	// Registration functions
	registerDraggable: (
		element: HTMLElement,
		options: {
			id: string
			parentId: string
			position: number
			type: 'extra' | 'day' | 'highlight' | string
			dayIndex?: number
		},
	) => void
	unregisterDraggable: (id: string) => void
	registerDropTarget: (
		element: HTMLElement,
		options: {
			id: string
			parentId: string
			position: number
			type: 'extra' | 'day' | 'highlight' | string
			dayIndex?: number
		},
	) => void
	unregisterDropTarget: (id: string) => void

	// Drag state as a readable store
	dragState: Readable<DragState>

	// Event handlers
	handleDragStart: (e: MouseEvent | TouchEvent, itemId: string) => void
	handleDragMove: (e: MouseEvent | TouchEvent) => void
	handleDragEnd: (e: MouseEvent | TouchEvent) => void

	// Utility functions
	isDragging: () => boolean
	isValidDropTarget: (targetId: string) => boolean
	getDropTargetAt: (x: number, y: number) => DropTarget | null
	calculatePositionUpdates: (
		draggedItem: DraggedItem,
		dropTarget: DropTarget,
		draggableItems: Map<string, DraggedItem>,
	) => { updates: BlockPositionUpdateInput[]; movedBlockId: string | null }
	updatePositions: (
		updates: BlockPositionUpdateInput[],
		movedBlockId: string | null,
	) => Promise<void>
	updateDropTargetPosition: (targetId: string, newPosition: number) => void
}

export function setDndContext(value: DndSegmentContextValue): void {
	setContext(DND_CONTEXT_KEY, value)
}

export function getDndContext(): DndSegmentContextValue {
	const context = getContext<DndSegmentContextValue>(DND_CONTEXT_KEY)
	if (!context) {
		throw new Error('DndSegmentContext must be used within a DndSegmentProvider')
	}
	return context
}
