<script lang="ts">
	import { getDndContext } from '$lib/context/dnd-context'
	import type { BlockFieldsFragment } from '$lib/queries/fragments/generated/BlockFields'
	import type { BlockPositionUpdateInput } from '$lib/graphql/types'
	import type { PublicBlockFieldsFragment } from '$lib/queries/fragments/generated/PublicBlockFields'
	import BlockTemplate from './BlockTemplate.svelte'
	import { getCustomColorBgClassName } from '$lib/utils/custom-colors'
	import { getCustomIconSvgUrl } from '$lib/utils/custom-icons'
	import LinkChainIcon from '$lib/components/icons/LinkChainIcon.svelte'
	import SpeechIcon from '$lib/components/icons/SpeechIcon.svelte'
	import { BlockType } from '$lib/graphql/enums'
	import ListSection from './ListSection.svelte'
	import { stopPropagation } from '$lib/utils/clickHelpers'

	interface Props {
		dayBlock: BlockFieldsFragment | PublicBlockFieldsFragment
		dayIndex: number
		destinationBlockId: string
		descedantBlocks?: (BlockFieldsFragment | PublicBlockFieldsFragment)[]
		isPublic?: boolean
		isImmutable?: boolean
		isLoading?: boolean
		isMobileScreen?: boolean
		previewBlocks?: string[]
		expandedBlockId?: string | null
		isMoving?: boolean
		blocksUpdating?: BlockPositionUpdateInput[]
		isDndModal?: boolean
		onclick?: (blockId: string) => void
		onexpand?: (blockId: string | null) => void
	}

	let {
		dayBlock,
		dayIndex,
		destinationBlockId,
		descedantBlocks = [],
		isPublic = false,
		isImmutable = false,
		isLoading = false,
		isMobileScreen = false,
		previewBlocks = [],
		expandedBlockId = null,
		isMoving = false,
		blocksUpdating = [],
		isDndModal = false,
		onclick,
		onexpand,
	}: Props = $props()

	let dayBlocksElement: HTMLElement | null = $state(null)

	// Get ordered blocks for this day
	let orderedDayBlocks = $derived(
		descedantBlocks
			?.filter((b) => b.parentId === dayBlock.id)
			?.sort((a, b) => a?.position - b?.position) ?? [],
	)

	function handleDayClick(blockId: string) {
		onclick?.(blockId)
	}

	// Get DnD context
	const dndContext = getDndContext()

	$effect(() => {
		if (dayBlocksElement && !isImmutable && !isPublic && !isMobileScreen) {
			// Register with initial position
			dndContext.registerDropTarget(dayBlocksElement, {
				id: `${dayBlock?.id}-container`,
				parentId: dayBlock?.id,
				position: orderedDayBlocks.length,
				type: 'day',
				dayIndex: dayIndex,
			})

			// Cleanup
			return () => {
				dndContext.unregisterDropTarget(`${dayBlock?.id}-container`)
			}
		}
	})

	// Update the block registration to include a data attribute for positioning
	function registerBlockAction(
		element: HTMLElement,
		{ block, index }: { block: BlockFieldsFragment | PublicBlockFieldsFragment; index: number },
	) {
		if (!isImmutable && !isPublic && !isMobileScreen) {
			// Add data attribute for position calculation
			element.setAttribute('data-block-item', '')
			element.setAttribute('data-position', index.toString())
			element.setAttribute('data-block-id', block.id)

			dndContext.registerDraggable(element, {
				id: block.id,
				parentId: dayBlock?.id,
				position: index,
				type: 'day',
			})
		}

		return {
			update({ block: newBlock, index: newIndex }) {
				if (!isImmutable && !isPublic && !isMobileScreen) {
					element.setAttribute('data-position', newIndex.toString())
					dndContext.registerDraggable(element, {
						id: newBlock.id,
						parentId: dayBlock?.id,
						position: newIndex,
						type: 'day',
					})
				}
			},
			destroy() {
				if (!isImmutable && !isPublic && !isMobileScreen) {
					dndContext.unregisterDraggable(block.id)
				}
			},
		}
	}

	// Touch event handlers
	function handleTouchStart(e: MouseEvent | TouchEvent, blockId: string) {
		if (!isImmutable && !isPublic && !isMobileScreen) {
			dndContext.handleDragStart(e, blockId)
		}
	}

	let dayBlockChildren = $state([])
	$effect(() => {
		dayBlockChildren = descedantBlocks
			?.filter((b) => b.parentId === dayBlock?.id)
			?.sort((a, b) => a?.position - b?.position)
	})
</script>

<div
	class="text-xs group text-brand-gray-4 hover:text-brand-gray-3 my-2 font-medium text-start flex items-center justify-between w-full"
>
	<button class="touch-manipulation" onclick={stopPropagation(() => handleDayClick(dayBlock?.id))}>
		Day {dayBlock?.position + 1}
	</button>

	<div class="flex items-center space-x-2 mr-1">
		{#if dayBlock?.sources?.length > 0}
			<button
				class="text-xs text-brand-gray-4 group-hover:text-brand-gray-2 flex items-center touch-manipulation"
				onclick={stopPropagation(() => handleDayClick(dayBlock?.id))}
			>
				{dayBlock?.sources?.length}
				<LinkChainIcon class="h-3 w-3 ml-1 text-brand-gray-4 group-hover:text-brand-gray-2" />
			</button>
		{/if}
		{#if dayBlock?.description?.length > 0}
			<button
				class="hover:cursor-pointer touch-manipulation"
				onclick={stopPropagation(() => handleDayClick(dayBlock?.id))}
			>
				<SpeechIcon
					class="h-[14px] w-[14px] text-brand-gray-5 dark:text-brand-gray-4 group-hover:text-brand-gray-2"
				/>
			</button>
		{/if}
	</div>
</div>

<div
	bind:this={dayBlocksElement}
	id={`${destinationBlockId}-day-blocks-${dayIndex}`}
	data-area={dayIndex}
	role="list"
	class="py-[1px]"
>
	{#each dayBlockChildren?.filter((block) => block !== null) ?? [] as block, index (block?.id)}
		{@const color = getCustomColorBgClassName(block?.color, block?.blockType)}
		{@const icon = getCustomIconSvgUrl(block?.icon, block?.blockType)}
		{@const routeEndId =
			index + 1 < dayBlockChildren?.length &&
			dayBlockChildren?.[index + 1]?.blockType != BlockType.List
				? dayBlockChildren?.[index + 1]?.id
				: null}
		{@const route = block?.routeAfter?.route ?? null}
		{@const routeBlockId = block?.routeAfter?.id ?? null}
		{@const isBlockPositionUpdating = blocksUpdating.some((update) => update.id === block?.id)}

		<!-- svelte-ignore a11y_no_static_element_interactions -->
		<!-- ontouchstart={(e) => handleTouchStart(e, block.id)} -->
		<div
			use:registerBlockAction={{ block, index }}
			onmousedown={(e) => handleTouchStart(e, block.id)}
			data-block-item
			data-position={index}
		>
			{#if block?.blockType === BlockType.List}
				{@const childBlocks = descedantBlocks
					.filter((b) => b.parentId === block?.id)
					?.sort((a, b) => a?.position - b?.position)}
				<ListSection
					{isBlockPositionUpdating}
					{previewBlocks}
					{onclick}
					listBlock={block}
					numInList={block?.numInList}
					{childBlocks}
					iconUrl={icon}
					bgColor={color}
					hasActions={!isImmutable && !isPublic}
					{isImmutable}
					{isPublic}
					{isLoading}
					{isMoving}
					{expandedBlockId}
					{isDndModal}
					{onexpand}
				/>
			{:else}
				<BlockTemplate
					{isBlockPositionUpdating}
					{previewBlocks}
					title={block?.title}
					description={block?.description}
					blockId={block?.id}
					{isPublic}
					{isImmutable}
					{isLoading}
					iconUrl={icon}
					bgColor={color}
					hasActions={!isImmutable && !isPublic}
					{routeEndId}
					{isMoving}
					{route}
					{routeBlockId}
					{onclick}
					{expandedBlockId}
					{isDndModal}
					{onexpand}
				/>
			{/if}
		</div>
	{/each}
</div>
