<script lang="ts">
	import StickyHeader from '$lib/components/display/StickyHeader.svelte'
	import type { BlockFieldsFragment } from '$src/lib/queries/fragments/generated/BlockFields'
	import { getContextClient } from '@urql/svelte'
	import {
		UpdateBlockPositionsDocument,
		type UpdateBlockPositionsMutation,
		type UpdateBlockPositionsMutationVariables,
	} from '$src/lib/queries/generated/UpdateBlockPositions'
	import { page } from '$app/state'
	import type { PanelType } from '$src/lib/utils/panel'
	import type { PublicBlockFieldsFragment } from '$src/lib/queries/fragments/generated/PublicBlockFields'
	import { track } from '$lib/utils/track'
	import QuickAction from './QuickAction.svelte'
	import CustomPinIcon from '$lib/components/icons/CustomPinIcon.svelte'
	import AddCalendar from '$lib/components/icons/AddCalendar.svelte'
	import CustomRegionIcon from '$lib/components/icons/CustomRegionIcon.svelte'
	import PaginateListIcon from '$lib/components/icons/PaginateListIcon.svelte'
	import {
		InsertDayBlockDocument,
		type InsertDayBlockMutation,
		type InsertDayBlockMutationVariables,
	} from '$lib/queries/generated/InsertBlock'
	import PlacePinIcon from '$lib/components/icons/PlacePinIcon.svelte'
	import ExtraBlocksSegment from './ExtraBlocksSegment.svelte'
	import DayBlocksSegment from './DayBlocksSegment.svelte'
	import Spinner from '$lib/components/loading/Spinner.svelte'
	import { BlockLimitPerTrip } from '$lib/utils/blocks'
	import UpIcon from '$lib/components/icons/UpIcon.svelte'
	import DownIcon from '$lib/components/icons/DownIcon.svelte'
	import type { BlockPositionUpdateInput } from '$lib/graphql/types'
	import LinkChainIcon from '$lib/components/icons/LinkChainIcon.svelte'
	import SpeechIcon from '$lib/components/icons/SpeechIcon.svelte'
	import { BlockType } from '$lib/graphql/enums'
	import { browser } from '$app/environment'
	import DragAndDropProvider from '$lib/components/dnd/DragAndDropProvider.svelte'
	import { stopPropagation } from '$lib/utils/clickHelpers'
	import { sanitizeAllTagsHtml } from '$src/lib/utils/sanitize'

	interface Props {
		destinationBlock: BlockFieldsFragment | PublicBlockFieldsFragment
		descedantBlocks?: (BlockFieldsFragment | PublicBlockFieldsFragment)[]
		isPublic?: boolean
		isImmutable?: boolean
		numBlocks?: number
		isMobileScreen?: boolean
		previewBlocks?: string[]
		expandedBlockId?: string | null
		isLoading?: boolean
		isMoving?: boolean
		numDestinationBlocks?: number
		indexPosition?: number
		isBlockDetails?: boolean
		hasPreviewForData?: boolean
		isDefaultRoot?: boolean
		hasNextPage?: boolean
		isDndModal?: boolean
		onsetPanel?: (panelState: PanelType, parentId: string | null) => void
		onclick?: (blockId: string | null) => void
		onreposition?: (blockId: string | null, direction: 'up' | 'down') => void
		onexpand?: (blockId: string | null) => void
	}

	let {
		destinationBlock,
		descedantBlocks = [],
		isPublic = false,
		isImmutable = true,
		numBlocks = 0,
		isMobileScreen = false,
		previewBlocks = [],
		expandedBlockId = null,
		isLoading = false,
		isMoving = false,
		numDestinationBlocks = 0,
		indexPosition = 0,
		isBlockDetails = false,
		hasPreviewForData = true,
		isDefaultRoot = true,
		hasNextPage = false,
		isDndModal = false,
		onsetPanel,
		onclick,
		onreposition,
		onexpand,
	}: Props = $props()

	let extraBlockId = $derived(
		descedantBlocks?.filter(
			(b) => b.parentId === destinationBlock?.id && b.blockType === BlockType.Extra,
		)?.[0]?.id ?? null,
	)

	let itineraryBlockId = $derived(
		descedantBlocks?.filter(
			(b) => b.parentId === destinationBlock?.id && b.blockType === BlockType.Itinerary,
		)?.[0]?.id ?? null,
	)

	let orderedExtraBlocks = $state([])
	$effect(() => {
		orderedExtraBlocks = descedantBlocks
			?.filter((b) => b.parentId === extraBlockId)
			?.sort((a, b) => a?.position - b?.position)
	})

	let orderedItineraryBlocks = $derived(
		descedantBlocks
			?.filter((b) => b.parentId === itineraryBlockId)
			?.sort((a, b) => a?.position - b?.position),
	)

	let blocksUpdating: BlockPositionUpdateInput[] = $state([])

	let tripId = $derived(page?.params?.slug)

	let hasCustomDescription = $state(false)

	$effect(() => {
		if (!browser) return

		let plainDescription = ''
		let cancelled = false
		sanitizeAllTagsHtml(destinationBlock?.description, browser, (result) => {
			if (!cancelled) {
				plainDescription = result.trim()

				hasCustomDescription =
					plainDescription?.length > 0 &&
					plainDescription !== destinationBlock?.destination?.address?.trim()
			}
		})

		return () => {
			cancelled = true
		}
	})

	function handleRepositionDestinationBlocks(direction: 'up' | 'down') {
		if (isPublic || isImmutable) return
		track('Reposition Destination Blocks', { tripId, blockId: destinationBlock?.id, direction })
		onreposition?.(destinationBlock?.id, direction)
	}

	// function handleSetMoving() {
	// 	isMoving = true
	// }

	function handleDestClick() {
		onclick?.(destinationBlock?.id)
	}

	function handleOnQuickAdd(type: PanelType) {
		if (isPublic) return
		track('Quick Add Block', { tripId, type })
		onsetPanel?.(type, destinationBlock?.id)
	}

	const client = getContextClient()
	const updateBlockPositions = (vars: UpdateBlockPositionsMutationVariables) =>
		client.mutation<UpdateBlockPositionsMutation, UpdateBlockPositionsMutationVariables>(
			UpdateBlockPositionsDocument,
			vars,
		)

	const insertBlock = (vars: InsertDayBlockMutationVariables) =>
		client.mutation<InsertDayBlockMutation, InsertDayBlockMutationVariables>(
			InsertDayBlockDocument,
			vars,
		)

	let addingDayBlockLoading = $state(false)

	async function handleInsertDayBlock() {
		if (numBlocks > BlockLimitPerTrip) return
		addingDayBlockLoading = true
		track('Add Day')
		const data = {
			parentID: itineraryBlockId,
		}
		insertBlock({ tripID: tripId, destinationID: destinationBlock?.id, input: data }).then(() => {
			addingDayBlockLoading = false
		})
	}

	async function handlePositionsUpdate(
		updates: BlockPositionUpdateInput[],
		movedBlockId: string | null,
	) {
		if (!updates.length) return
		// console.log('handlePositionsUpdate updates', updates)

		isLoading = true
		try {
			blocksUpdating = updates

			await updateBlockPositions({
				tripID: tripId,
				input: updates,
				isDestination: false,
				movedBlockId,
				isHighlight: false,
			})
		} catch (error) {
			console.error('Error updating block positions:', error)
		} finally {
			blocksUpdating = []
			isLoading = false
			isMoving = false
		}
	}
</script>

<DragAndDropProvider
	destinationId={destinationBlock?.id}
	onPositionsUpdate={handlePositionsUpdate}
	disabled={isPublic || isImmutable || isLoading}
>
	<div id={destinationBlock?.id} class="w-full">
		{#if !isDndModal}
			<StickyHeader
				class={`top-24 lg:top-32 w-full group`}
				buttonClass={`${isBlockDetails ? 'dark:hover:text-brand-gray-2 cursor-auto' : ''}`}
				onclick={isBlockDetails ? null : handleDestClick}
				title={isBlockDetails ? 'Itinerary' : (destinationBlock?.title ?? 'Location')}
			>
				<div class="flex flex-col items-end">
					{#if !isImmutable && !isPublic && numDestinationBlocks > 1 && !isBlockDetails}
						<div class="flex flex-row group-hover:opacity-100 opacity-0 space-x-2 -mt-4 mr-[2px]">
							<button
								disabled={indexPosition === 0 || hasNextPage}
								onclick={stopPropagation(() => handleRepositionDestinationBlocks('up'))}
								class="text-brand-gray-4 hover:text-white disabled:text-brand-gray-4 touch-manipulation"
							>
								<UpIcon class="h-4 w-4" />
							</button>
							<button
								disabled={indexPosition === numDestinationBlocks - 1 || hasNextPage}
								onclick={stopPropagation(() => handleRepositionDestinationBlocks('down'))}
								class="text-brand-gray-4 hover:text-white disabled:text-brand-gray-4 touch-manipulation"
							>
								<DownIcon class="h-4 w-4" />
							</button>
						</div>
					{/if}
					{#if hasPreviewForData}
						<div class="flex items-center space-x-2 mr-1">
							{#if destinationBlock?.sources?.length > 0}
								<button
									onclick={stopPropagation(handleDestClick)}
									class="group text-xs mt-1 flex items-center touch-manipulation"
								>
									<p class="text-brand-gray-4 group-hover:text-brand-gray-2">
										{destinationBlock?.sources?.length}
									</p>
									<LinkChainIcon
										class="h-3 w-3 ml-1 text-brand-gray-4 group-hover:text-brand-gray-2"
									/>
								</button>
							{/if}
							{#if hasCustomDescription}
								<button
									class="hover:cursor-pointer touch-manipulation"
									onclick={stopPropagation(handleDestClick)}
								>
									<SpeechIcon
										class="h-[14px] w-[14px] text-brand-gray-5 dark:text-brand-gray-4 group-hover:text-brand-gray-2 mt-1"
									/>
								</button>
							{/if}
						</div>
					{/if}
				</div>
			</StickyHeader>
		{/if}
		<div class="flex flex-col py-2">
			<ExtraBlocksSegment
				destinationBlockId={destinationBlock?.id}
				{blocksUpdating}
				{orderedExtraBlocks}
				{descedantBlocks}
				{previewBlocks}
				{extraBlockId}
				{isPublic}
				{isImmutable}
				{isLoading}
				{isMoving}
				{isMobileScreen}
				{expandedBlockId}
				{isDndModal}
				{onexpand}
				{onclick}
			/>

			{#if orderedItineraryBlocks?.length > 0}
				{#each orderedItineraryBlocks as dayBlock, dayIndex (dayBlock.id)}
					<DayBlocksSegment
						{blocksUpdating}
						{previewBlocks}
						{dayIndex}
						destinationBlockId={destinationBlock?.id}
						{dayBlock}
						{descedantBlocks}
						{isPublic}
						{isImmutable}
						{isLoading}
						{isMoving}
						{isMobileScreen}
						{expandedBlockId}
						{isDndModal}
						{onclick}
						{onexpand}
					/>
				{/each}
			{/if}

			{#if !isPublic && !isImmutable && !isDndModal}
				{#if orderedExtraBlocks?.length > 0 || orderedItineraryBlocks?.length > 0}
					<p class="text-xs text-brand-gray-4 mt-3">Add to Trip</p>
				{:else}
					<p class="text-xs text-brand-gray-4 mt-3">
						No items added, yet. Start by adding one of the following
					</p>
				{/if}

				{#if numBlocks >= BlockLimitPerTrip}
					<p class="text-xs text-brand-gray-4 mt-1">
						You have reached the item limit of {BlockLimitPerTrip} for this trip
					</p>
				{:else}
					<div class="flex flex-row flex-shrink-0 space-x-4 py-2 overflow-x-scroll hide-scrollbar">
						{#if !isDefaultRoot}
							<QuickAction title="Day" onclick={handleInsertDayBlock}>
								{#if addingDayBlockLoading}
									<Spinner size="sm" embedded />
								{:else}
									<AddCalendar class="h-4 w-4 m-auto text-brand-gray-4/70" />
								{/if}
							</QuickAction>
						{/if}
						<QuickAction title="Place" onclick={() => handleOnQuickAdd('place')}>
							<PlacePinIcon class="h-[14px] w-[14px] m-auto text-brand-gray-4/70" />
						</QuickAction>
						<QuickAction title="Pin" onclick={() => handleOnQuickAdd('pin')}>
							<CustomPinIcon class="h-[14px] w-[14px] m-auto text-brand-gray-4/70" />
						</QuickAction>
						<QuickAction title="Region" onclick={() => handleOnQuickAdd('region')}>
							<CustomRegionIcon class="h-[14px] w-[14px] m-auto text-brand-gray-4/70" />
						</QuickAction>
						<QuickAction title="List" onclick={() => handleOnQuickAdd('list')}>
							<PaginateListIcon class="h-[14px] w-[14px] m-auto text-brand-gray-4/70" />
						</QuickAction>
					</div>
				{/if}
			{/if}
		</div>
	</div>
</DragAndDropProvider>
