<script lang="ts">
	import { stopPropagation } from '$lib/utils/clickHelpers'

	interface Props {
		title: string
		blockId?: string | null
		children?: import('svelte').Snippet
		onclick?: (blockId: string | null) => void
	}

	let { title, blockId = null, children, onclick }: Props = $props()

	function handleClick() {
		onclick?.(blockId)
	}
</script>

<button
	onclick={stopPropagation(handleClick)}
	class="flex group items-center space-x-2 touch-manipulation"
>
	{@render children?.()}
	<div
		class="text-[10px] font-medium text-brand-gray-5 group-hover:text-black dark:group-hover:text-white dark:text-brand-gray-3 transition-colors"
	>
		{title}
	</div>
</button>
